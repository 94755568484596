import Vue from 'vue';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { IProduct } from '~/interfaces/product';
import { Cart, CartItem, CartItemOption, CartTotal } from '~/interfaces/cart';
import cartDetailApi from '~/api/cartdetail';
import cartHeaderApi from '~/api/cartheader';
import orderDetailApi from '~/api/orderdetail';

export interface CartState extends Cart {
  lastItemId: number;
}

function getDefaultState(): CartState {
  return {
    lastItemId: 0,
    items: [],
    quantity: 0,
    subtotal: 0,
    totals: [],
    total: 0,
  };
}

export const state = getDefaultState;

export interface CartItemQuantity {
  itemId: number;
  value: number;
}

export type CartAddPayload = {
  detailId?: number;
  product: IProduct;
  options?: CartItemOption[];
  quantity?: number;
};

export type CartRemovePayload = {
  itemId: number;
};

export type CartUpdateQuantitiesPayload = CartItemQuantity[];

function findItemIndex(
  items: CartItem[],
  product: IProduct,
  options: CartItemOption[]
): number {
  return items.findIndex((item) => {
    if (
      item.product.id !== product?.id ||
      item.options.length !== options.length
    ) {
      return false;
    }

    for (let i = 0; i < options.length; i += 1) {
      const option = options[i];
      const itemOption = item.options.find(
        (itemOption) =>
          itemOption.optionId === option.optionId &&
          itemOption.valueId === option.valueId
      );

      if (!itemOption) {
        return false;
      }
    }

    return true;
  });
}

function calcSubtotal(items: CartItem[]): number {
  return items.reduce((subtotal, item) => subtotal + item.total, 0);
}

function calcQuantity(items: CartItem[]): number {
  return items.reduce((quantity, item) => quantity + item.quantity, 0);
}

function calcTotals(items: CartItem[]): CartTotal[] {
  if (items.length === 0) {
    return [];
  }

  const subtotal = calcSubtotal(items);

  return [
    {
      type: 'shipping',
      title: 'Shipping',
      price: 25,
    },
    {
      type: 'gst',
      title: 'GST',
      price: subtotal * 0.07,
    },
  ];
}

function calcTotal(subtotal: number, totals: CartTotal[]): number {
  return totals.reduce((acc, extraLine) => acc + extraLine.price, subtotal);
}

// noinspection JSUnusedGlobalSymbols
export const mutations: MutationTree<CartState> = {
  add(state, payload: any) {
    state.items = [];
    state.quantity = 0;
    state.total = 0;

    state.items = payload.items;
    state.quantity = payload.quantity;
    state.total = payload.total;
  },
  update(state, payload: any) {
    //
  },
  updateQuantity(state, payload: any) {
    const index = state.items.indexOf(
      state.items.find((x: any) => x.id == payload.id)
    );

    state.items[index].quantity = payload.quantity;
    state.items[index].total = payload.total;

    state.quantity = 0;
    state.total = 0;
    for (const item of state.items) {
      state.quantity += item.quantity;
      state.total += item.total;
    }

    Vue.notify({
      type: 'primary',
      text: `Quantity updated: <br />${state.items[index].product.Name}`,
      duration: 5000,
    });
  },
  updateQuantities(state, payload: any) {
    const index = state.items.indexOf(
      state.items.find((x: any) => x.id == payload.id)
    );

    state.items[index].quantity = payload.quantity;
    state.items[index].total = payload.total;

    state.quantity = 0;
    state.total = 0;
    for (const item of state.items) {
      state.quantity += item.quantity;
      state.total += item.total;
    }
  },
  removeAll(state) {
    state.items = [];
    state.quantity = 0;
    state.total = 0;

    const id = Number(localStorage.getItem('activeCartID'));

    cartDetailApi
      .clearCart(id)
      .then(() => {
        localStorage.setItem('activeCartID', '0');
      })
      .catch(() => {
        //
      })
      .finally(() => {
        //
      });
  },
  remove(state, payload: any) {
    const item = state.items.find((x) => x.id == payload.id);
    const index = state.items.indexOf(item);

    state.items.splice(index, 1);
    state.total -= item ? item.total : 0;
    state.quantity -= item ? item.quantity : 0;

    if (state.items && state.items.length == 0) {
      state.items = [];
      state.quantity = 0;
      state.total = 0;

      const id = Number(localStorage.getItem('activeCartID'));

      cartDetailApi
        .clearCart(id)
        .then(() => {
          localStorage.setItem('activeCartID', '0');
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    }
  },
  addActiveCart(state, payload: any) {
    state.items = payload.items;
    state.quantity = payload.quantity;
    state.total = payload.total;
  },
  resetStateValues(state) {
    state.items = [];
    state.quantity = 0;
    state.total = 0;
  },
  checkOutCart(state) {
    state.items = [];
    state.quantity = 0;
    state.total = 0;

    const id = Number(localStorage.getItem('activeCartID'));

    cartDetailApi
      .checkOut(id)
      .then(() => {
        //
      })
      .catch(() => {
        //
      })
      .finally(() => {
        localStorage.setItem('activeCartID', '0');
      });
  },
  loadToCart(state, payload) {
    state.items = [];
    state.quantity = 0;
    state.total = 0;

    const items: any = [];
    let qty = 0;
    let total = 0;
    const id = payload.id;
    const details = payload?.details?.data;
    cartDetailApi
      .loadCart(id)
      .then(() => {
        localStorage.setItem('activeCartID', id);
      })
      .catch(() => {
        //
      })
      .finally(() => {
        //
      });

    if (payload && details) {
      for (const e of details) {
        const prod = e?.product?.data;

        const p = {
          ID: prod.id,
          Name: prod.name,
          Description: prod.description,
          Brand: prod.brand,
          Weight: prod.weight,
          Specifications: prod.specifications,
          ManufacturerID: prod.manufacturerid,
          CategoryID: prod.category_id,
          Price: prod.price,
          NewPrice: prod.newprice,
          ProductType: prod.producttype,
          Inventory: prod.inventory,
          SKU: prod.sku,
          Thumbnail: prod.thumbnail,
          Model: prod.model,
          Review: prod.review,
          Availability: prod.availability,
          IsPopular: prod.ispopular,
          Barcode: prod.barcode,
          Status: 'O',
        };

        items.push({
          id: e.id,
          product: p,
          options: [],
          price: e.price,
          quantity: e.quantity,
          total: e.total,
        });

        qty += e.quantity;
        total += e.total;
      }
    }

    state.items = items;
    state.quantity = qty;
    state.total = total;
  },
  saveDraftLoadDraft(state, payload) {
    const items: any = [];
    let qty = 0;
    let total = 0;
    const id = payload.id;
    const details = payload?.details?.data;
    cartDetailApi
      .loadCart(id)
      .then(() => {
        localStorage.setItem('activeCartID', id);
      })
      .catch(() => {
        //
      })
      .finally(() => {
        //
      });

    if (payload && details) {
      for (const e of details) {
        const prod = e?.product?.data;

        const p = {
          ID: prod.id,
          Name: prod.name,
          Description: prod.description,
          Brand: prod.brand,
          Weight: prod.weight,
          Specifications: prod.specifications,
          ManufacturerID: prod.manufacturerid,
          CategoryID: prod.category_id,
          Price: prod.price,
          NewPrice: prod.newprice,
          ProductType: prod.producttype,
          Inventory: prod.inventory,
          SKU: prod.sku,
          Thumbnail: prod.thumbnail,
          Model: prod.model,
          Review: prod.review,
          Availability: prod.availability,
          IsPopular: prod.ispopular,
          Barcode: prod.barcode,
          Status: 'O',
        };

        items.push({
          id: e.id,
          product: p,
          options: [],
          price: e.price,
          quantity: e.quantity,
          total: e.total,
        });

        qty += e.quantity;
        total += e.total;
      }
    }

    state.items = items;
    state.quantity = qty;
    state.total = total;
  },
};

// noinspection JSUnusedGlobalSymbols
export const actions: ActionTree<CartState, {}> = {
  async add({ commit }, payload: CartAddPayload): Promise<void> {
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        let items: any = [],
          qty = 0,
          total = 0;
        const { product, options = [], quantity = 1 } = payload;
        const activeCartID = Number(localStorage.getItem('activeCartID'));
        const params: any = {
          header_id: activeCartID ? activeCartID : 0,
          product_id: product ? +product.id : 0,
          price: product ? product.price : 0,
          quantity: quantity,
          total: product ? product.price * quantity : 0,
          options: options,
          // user_id: Number(localStorage.getItem('userId')),
        };

        cartDetailApi
          .addToCart(params.header_id, params)
          .then(({ data }) => {
            if (data && params.header_id == 0) {
              localStorage.setItem('activeCartID', data.header_id);
            }

            if (data.details && data.details.length > 0) {
              for (const e of data.details) {
                items.push({
                  id: e.id,
                  product: e.product,
                  options: [],
                  price: e.price,
                  quantity: e.quantity,
                  total: e.total,
                });

                qty += e.quantity;
                total += e.total;
              }
            }

            if (data) {
              Vue.notify({
                type: 'success',
                text: `Product "${product.name}" added to cart!`,
              });
            }
          })
          .catch(() => {
            //
          })
          .finally(() => {
            commit('add', { quantity: qty, items: items, total: total });
          });

        resolve();
      }, 500);
    });
  },
  async update({ commit }, payload: any): Promise<void> {
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        commit('update', payload);
        resolve();
      }, 500);
    });

    commit('add', payload);
  },
  async updateQuantity({ commit }, payload: any): Promise<void> {
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        const data = payload.items;

        if (data) {
          const id = data.id || data.ID || 0;
          const qty = { quantity: data.Quantity || data.quantity || 0 };
          const total = qty.quantity * data.Price || data.price || 0;
          cartDetailApi
            .updateQuantity(id, qty)
            .then(() => {
              //
            })
            .catch(() => {
              //
            })
            .finally(() => {
              commit('updateQuantity', {
                id: id,
                quantity: qty.quantity,
                total,
              });
            });
        }

        resolve();
      }, 500);
    });
  },
  async updateQuantities({ commit }, payload: any): Promise<void> {
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        for (const data of payload.items) {
          if (data) {
            const id = data.id || data.ID || 0;
            const qty = { quantity: data.Quantity || data.quantity || 0 };
            const total = qty.quantity * data.Price || data.price || 0;
            cartDetailApi
              .updateQuantity(id, qty)
              .then(() => {
                //
              })
              .catch(() => {
                //
              })
              .finally(() => {
                commit('updateQuantities', {
                  id: id,
                  quantity: qty.quantity,
                  total,
                });
              });
          }
        }

        resolve();
      }, 500);
    });
  },
  async remove({ commit }, payload: any): Promise<void> {
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        cartDetailApi
          .delete(payload.id)
          .then(() => {
            //
          })
          .catch(() => {
            //
          })
          .finally(() => {
            commit('remove', payload);
          });

        resolve();
      }, 500);
    });
  },
  async removeAll({ commit }, payload): Promise<void> {
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        commit('removeAll');

        resolve();
      }, 500);
    });
  },
  async addActiveCart({ commit }, payload: CartAddPayload): Promise<void> {
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        let items: any = [],
          qty = 0,
          total = 0;
        const activeCartID = Number(localStorage.getItem('activeCartID'));
        cartHeaderApi
          .getByUser()
          .then(({ data }) => {
            if (data && activeCartID == 0) {
              localStorage.setItem('activeCartID', data.header_id);
            }

            if (data.details && data.details.length > 0) {
              for (const e of data.details) {
                items.push({
                  id: e.id,
                  product: e.product,
                  options: e.options,
                  price: e.price,
                  quantity: e.quantity,
                  total: e.total,
                });

                qty += e.quantity;
                total += e.total;
              }
            }
          })
          .catch(() => {
            //
          })
          .finally(() => {
            commit('addActiveCart', {
              quantity: qty,
              items: items,
              total: total,
            });
          });

        resolve();
      }, 500);
    });
  },
  async resetStateValues({ commit }): Promise<void> {
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        commit('resetStateValues');

        resolve();
      }, 500);
    });
  },
  async checkOutCart({ commit }): Promise<void> {
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        commit('checkOutCart');

        resolve();
      }, 500);
    });
  },
  async loadToCart({ commit }, payload: any): Promise<void> {
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        commit('loadToCart', payload);

        resolve();
      }, 500);
    });
  },
  async saveDraftLoadDraft({ commit }, payload: any): Promise<void> {
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        commit('saveDraftLoadDraft', payload);

        resolve();
      }, 500);
    });
  },
};

export const getters: GetterTree<CartState, {}> = {
  quantity(store) {
    return store.quantity;
  },

  getCart(store) {
    return { items: store.items, total: store.total };
  },
};
